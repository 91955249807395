import { IMG_LOVE_STORY } from '@/constants/assets';

export default [
  {
    img: IMG_LOVE_STORY,
    title: 'Love Story',
    content: `Yohana and Kristofer's love story began during their university days, but their relationship blossomed just before Yohana had to leave the country for further studies. Despite the distance, their commitment and love for each other only grew stronger during their time apart.
    <br /><br />
    After enduring a year and a half of being apart, Kristofer relocated to be with Yohana, marking a new chapter in their journey. Their time apart taught them to cherish every moment together, and they spent the next six years building a life in a foreign land. Facing challenges together, they learned the value of resilience, understanding, and trust.
    <br /><br />
    Recently, their love expanded beyond each other, welcoming two furry companions, Suki and Kaia, into their lives. Their shared love for their dogs became an emblem of their commitment and unity, forging new dimensions to their relationship.
    <br /><br />
    Now, as they stand on the threshold of marriage, Yohana and Kristofer are excited about the future, confident that the love they nurtured over seven remarkable years will continue to guide them through the beautiful journey ahead.`,
  },
];